/* colors */
.primary-red {
  color: #992c37;
}

.primary-blue {
  color: #1d5b6b;
}

.primary-light-blue {
  color: #277b77;
}

.primary-yellow {
  color: #eaa12e;
}

.secondary-red {
  color: #e05859;
}

.secondary-blue {
  color: #24a2c4;
}

.secondary-light-blue {
  color: #7ac2bf;
}

.secondary-yellow {
  color: #ce6b2e;
}

/* fonts */
@font-face {
  font-family: TrendSansFour;
  src: url(../../fonts/TrendSans-Four.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: TrendSansOne;
  src: url(../../fonts/TrendSans-One.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: AvenirBlack;
  src: url(../../fonts/Avenir-Black.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: AvenirMedium;
  src: url(../../fonts/Avenir-Medium.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: AvenirRoman;
  src: url(../../fonts/Avenir-Roman.woff2) format("woff2");
  font-display: swap;
}

strong {
  font-weight: bold;
}

h1 {
  font-family: TrendSansFour;
  line-height: normal;
  /* hero title */
  text-shadow: 2px 2px 4px #000000;
  font-weight: 400;
}

h2,
h3,
h4 {
  font-family: TrendSansOne;
  line-height: normal;
}

h5,
.label {
  font-family: AvenirBlack;
  line-height: normal;
}

.caption {
  font-family: AvenirMedium;
  line-height: normal;
}

.pLarge {
  font-family: AvenirRoman;
  line-height: normal;
}

p,
.sliceContent li {
  font-family: AvenirRoman;
}

.pSmall {
  font-family: AvenirRoman;
  line-height: normal;
}

/* Desktop */
h1,
h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

h4,
h5 {
  font-size: 28px;
}

.label {
  font-size: 18px;
}

.caption,
.pSmall {
  font-size: 16px;
}

.pLarge {
  font-size: 28px;
}

p,
.sliceContent li {
  font-size: 18px;
  line-height: 20px;
}

/* tablet */
@media screen and (max-width: 1023px) {

  h1,
  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 32px;
  }

  h4,
  h5 {
    font-size: 24px;
  }

  .label,
  .pLarge {
    font-size: 18px;
  }

  .caption,
  .pSmall {
    font-size: 16px;
  }

  p,
  .sliceContent li {
    font-size: 14px;
    line-height: normal;
  }
}

/* mobile */
@media screen and (max-width: 767px) {

  h1,
  h2 {
    font-size: 32px;
  }

  h3,
  .label {
    font-size: 28px;
  }

  h4,
  h5 {
    font-size: 25px;
  }

  .caption {
    font-size: 14px;
  }

  .pLarge {
    font-size: 18px;
  }

  p,
  .sliceContent li {
    font-size: 16px;
    line-height: normal;
  }

  .pSmall {
    font-size: 13px;
  }

  /* chrome */
  body::-webkit-scrollbar {
    display: none;
  }

  /* safari */
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* hero */
.headerPadding {
  padding-top: 86px;
}

/* Carousal */
.carouselContainer {
  box-sizing: content-box;
  margin-top: 0;
}

.blueline {
  padding: 0;
  vertical-align: top;
  background: #7ac2bf;
  margin-top: 16px;
  height: 6px;
}
.commblueline {
  padding: 0;
  vertical-align: top;
  background: #7ac2bf;
  margin-top: 8px;
  height: 6px;
}

.carouselText {
  text-align: center;
}

.carouselText ul {
  text-align: left;
}

/* .carousel .carousel-control-prev,
.carousel .carousel-control-next {
  bottom: 81%;
} */
.carousel {
  background: #1d5b6b;
  padding-top: 30px;
  padding-bottom: 30px;
}

.carouselTitle {
  color: darkgray;
  text-align: left;
  max-width: fit-content;
}

.carouselSubtitle [class="20px"] {
  font-size: 20px;
}

.carouselSubtitle .year {
  font-size: 200px;
}

@media screen and (max-width: 1023px) {
  .carouselSubtitle .year {
    font-size: 150px;
  }
}

@media screen and (max-width: 767px) {
  .carouselSubtitle .year {
    font-size: 100px;
  }
}

.carouselSubtitle .year {
  color: #eaa12e;
  line-height: normal;
}

.custom-caption-style {
  position: unset;
  z-index: 10;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-caption {
  padding-top: 0;
}

/* Feature Item */
@media (min-width: 767px) {
  .featuredItem .right {
    order: 1;
  }
}

.featuredItem .right .fiTitle {
  left: 110px;
}

.featuredItem .fiTitle {
  position: absolute;
  top: -24px;
  left: -30px;
  width: 80%;
}

.column-image {
  padding: 0px;
  max-width: 25rem;
  /* background-color: #fff !important; */
  border-radius: 0;
}

@media screen and (max-width: 767px) {
  .featuredItem .fiTitle:first-child {
    position: absolute;
  }
}

.featuredItem .fiTitle .longArrow {
  width: 25px;
  height: 20px;
}

.featuredItem .fiTitle {
  background-color: #24a2c4;
  border-radius: 0;
}

.fiTitle {
  display: flex;
}

.fiTitle a {
  color: white;
}

.fiTitle a:hover {
  text-decoration: underline;
}

.column-text {
  text-align: center;
  font-style: normal;
  padding: 2rem;
  text-align: initial;
  margin: 20px, 0px;
}

.column-text ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.featuredItem .fiTitle p {
  color: white;
  margin-left: 10px;
}

/* custom-header */
.customHeader ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.customHeader span {
  display: block;
}

.chBlue {
  background: #1d5b6b;
}
.chGreen {
  background: #7bc9cc;
}

.chGreen .title {
  color: white;
}

.chBlue .title,
.chBlue .description {
  color: white;
}

.chWhite .titletype2 {
  color: #303e47;
  box-shadow: none;
}
.chWhite .title {
  color: #1d5b6b;
}

.chWhite .description {
  color: black;
}

.chWhite .description li {
  position: relative;
}

.chWhite .description li::before {
  content: "\2022";
  color: #7ac2bf;
  position: absolute;
  left: -18px;
}

.chWhite .description ul {
  list-style: none;
}

/* ch react bootstrap */
.btn-chBlue {
  background-color: white;
  color: #1d5b6b;
}

.btn-chBlue.active {
  color: white;
  background-color: #1d5b6b;
}

.btn-chWhite {
  background-color: #1d5b6b;
  color: white;
}

/* style to remove after switching every page */
.oldfi .column-image .card-header {
  position: absolute;
  top: -24px;
  left: -55px;
  width: 80%;
  background-color: #24a2c4;
  border-radius: 0;
}

.oldfi .column-image .card-header.lftAlmnt:first-child {
  left: 150px;
}

.oldfi .column-image .card-header {
  color: white;
}

.accordion-list-style {
  list-style-type: initial;
  list-style-position: inside;
  margin-bottom: 0.45rem;
}

.accordion .accordion-item .title {
  background: #eaa12e;
  color: #fff;
  font-weight: bold;
  font-size: larger;
}

.accordion {
  border: none;
}

.accordion .accordion-item {
  margin: 0.45rem;
}

.accordion .accordion-item.active .title {
  background: #eaa12e;
  color: #fff;
}

.accordion .accordion-item.active .panel {
  background: #fff2dc;
}

.accordion .accordion-item.active .title:after {
  content: "\2582";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 16px;
}

.accordion .accordion-item .title:after {
  content: "\002B";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 26px;
}

.accordion .accordion-item:first-child .title,
.accordion .accordion-item:last-child .title {
  border-radius: 0;
}

/* social module */
#socialModule {
  background-color: #eaa12e;
}

/* ImageGallery */
.imageGallery {
  margin-left: 15%;
  margin-right: 15%;
}

.galleryItem {
  align-self: center;
}

.categoryButtons .toggleButton {
  border-radius: 0;
  margin-left: -1px;
  width: 250px;
  font-size: 12px;
  font-family: AvenirRoman;
  font-weight: bold;
}

.ril-caption {
  width: 100%;
  text-align: center;
}

.ril__navButtonNext {
  right: 13%;
}

.ril__navButtonPrev {
  left: 13%;
}

@media screen and (max-width: 1023px) {
  .ril__navButtonNext {
    right: 7%;
  }

  .ril__navButtonPrev {
    left: 7%;
  }
}

@media screen and (max-width: 767px) {
  .ril__navButtonNext {
    right: 0;
  }

  .ril__navButtonPrev {
    left: 0;
  }
}

.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}

.ril-image-current {
  max-height: 300px;
}

.ril-image-next,
.ril-image-prev {
  display: none;
}

.pdf .fa-file-pdf {
  color: red;
}

.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}

/* accordion */
.accordion-description ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 0;
  }
}

/* header */

.position-static.show.dropdown.nav-item:after {
  content: "\25B2";
  position: absolute;
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

#NavBar {
  font-family: AvenirRoman;
  text-transform: uppercase;
  /* padding: 10px 0; */
}

#NavBar .navbar-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #eaa12e;
}

#NavBar .navbar-nav .nav-item .dropdown-item.active {
  color: white;
  background-color: #eaa12e;
}

a.nav-link {
  color: white;
}

#NavBar .dropdown-item {
  font-family: AvenirRoman;
  color: #eaa12e;
  font-weight: bold;
  margin-right: 15px;
  max-width: max-content;
}

#NavBar a.nav-link {
  padding-left: 6px;
  padding-right: 6px;
}

.dropdown-menu.show {
  padding: 10px 100px;
  justify-content: center;
  display: flex;
  text-align: center;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  box-shadow: 0px 15px 10px -15px #111;
  border: none;
}

.hero-text-hide {
  display: none;
}

@media screen and (max-width: 1199px) {
  .dropdown-menu.show {
    padding: 0;
    display: block;
  }

  .dropdown-menu {
    top: auto;
    left: auto;
    width: auto;
  }

  a.nav-link {
    padding-left: 6px;
    color: white;
  }

  #NavBar {
    background-color: #992c37 !important;
  }

  .mobilemenu-submenu-style .navbar-toggler {
    /* match the same height as logo */
    height: 55px;
  }
}

/* notice */
.notice {
  margin: 20px auto 0px;
}

.underline {
  text-decoration: underline;
}

/* table */
.tableSlice {
  margin: auto;
  width: 85%;
}

/* textblock */
.textBlock {
  font-size: 18px;
  line-height: 20px;
}

/* news articles */
div#news_article b {
  font-weight: bold;
}

div#news_article li {
  font-size: 18px;
  font-family: AvenirRoman;
}

div#news_article p {
  margin-bottom: 1em;
}

/* yellowHeader */
.yellowTitle {
  color: #eaa12e;
  text-align: center;
  margin-top: 100px;
}

/* contacts */
.flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
.my-5.row.justify-content-center.featuredItem {
  text-align: -webkit-center;
}
.carouselType2Text, p {
   font-size: 14px!important; 
}
.featuredItem .fiTitle p {
  
  margin-left: 0px;
  text-align: initial;
}
}

.contactLeft {
  text-align: center;
  padding: 3em 0;
}

.flex-child {
  flex: 1;
}

.btn-group-vertical {
  margin-top: 2em;
}

.linkColor>p>a {
  color: rgb(153, 44, 55);
}

.whiteText>p {
  color: white;
}

.contact-custom-card-style {
  background-color: #eaa12e;
  text-align: center;
  padding: 4.25rem;
}

.contact-sidebar-subheader {
  color: dimgray;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* colors */
.primary-red {
  color: #992c37;
}


.primary-blue {
  color: #1d5b6b;
}


.primary-light-blue {
  color: #277b77;
}


.primary-yellow {
  color: #eaa12e;
}


.secondary-red {
  color: #e05859;
}


.secondary-blue {
  color: #24a2c4;
}


.secondary-light-blue {
  color: #7ac2bf;
}


.secondary-yellow {
  color: #ce6b2e;
}


/* fonts */
@font-face {
  font-family: TrendSansFour;
  src: url(../../fonts/TrendSans-Four.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: TrendSansOne;
  src: url(../../fonts/TrendSans-One.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: AvenirBlack;
  src: url(../../fonts/Avenir-Black.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: AvenirMedium;
  src: url(../../fonts/Avenir-Medium.woff2) format("woff2");
  font-display: swap;
}


@font-face {
  font-family: AvenirRoman;
  src: url(../../fonts/Avenir-Roman.woff2) format("woff2");
  font-display: swap;
}


strong {
  font-weight: bold;
}


h1 {
  font-family: TrendSansFour;
  line-height: normal;
  /* hero title */
  text-shadow: 2px 2px 4px #000000;
  font-weight: 400;
}


h2,
h3,
h4 {
  font-family: TrendSansOne;
  line-height: normal;
}


h5,
.label {
  font-family: AvenirBlack;
  line-height: normal;
}


.caption {
  font-family: AvenirMedium;
  line-height: normal;
}


.pLarge {
  font-family: AvenirRoman;
  line-height: normal;
}


p,
.sliceContent li {
  font-family: AvenirRoman;
}


.pSmall {
  font-family: AvenirRoman;
  line-height: normal;
}

/* Desktop */
h1,
h2 {
  font-size: 48px;
}


h3 {
  font-size: 36px;
}


h4,
h5 {
  font-size: 28px;
}


.label {
  font-size: 18px;
}


.caption,
.pSmall {
  font-size: 16px;
}


.pLarge {
  font-size: 28px;
}


p,
.sliceContent li {
  font-size: 18px;
  line-height: 20px;
}


/* tablet */
@media screen and (max-width: 1023px) {


  h1,
  h2 {
    font-size: 40px;
  }


  h3 {
    font-size: 32px;
  }


  h4,
  h5 {
    font-size: 24px;
  }


  .label,
  .pLarge {
    font-size: 18px;
  }


  .caption,
  .pSmall {
    font-size: 16px;
  }


  p,
  .sliceContent li {
    font-size: 14px;
    line-height: normal;
  }
}


/* mobile */
@media screen and (max-width: 767px) {


  h1,
  h2 {
    font-size: 32px;
  }


  h3,
  .label {
    font-size: 28px;
  }


  h4,
  h5 {
    font-size: 25px;
  }


  .caption {
    font-size: 14px;
  }


  .pLarge {
    font-size: 18px;
  }


  p,
  .sliceContent li {
    font-size: 16px;
    line-height: normal;
  }


  .pSmall {
    font-size: 13px;
  }

  /* chrome */
  body::-webkit-scrollbar {
    display: none;
  }


  /* safari */
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* hero */
.headerPadding {
  padding-top: 86px;
}


/* Carousal */
.carouselContainer {
  box-sizing: content-box;
  margin-top: 0;
}


.blueline {
  padding: 0;
  vertical-align: top;
  background: #7ac2bf;
  margin-top: 16px;
  height: 6px;
}


.carouselText {
  text-align: center;
}


.carouselText ul {
  text-align: left;
}


/* .carousel .carousel-control-prev,
.carousel .carousel-control-next {
  bottom: 81%;
} */
.carousel {
  background: #1d5b6b;
  padding-top: 30px;
  padding-bottom: 30px;
}


.carouselTitle {
  color: darkgray;
  text-align: left;
  max-width: fit-content;
}


.carouselSubtitle [class="20px"] {
  font-size: 20px;
}


.carouselSubtitle .year {
  font-size: 200px;
}


@media screen and (max-width: 1023px) {
  .carouselSubtitle .year {
    font-size: 150px;
  }
}


@media screen and (max-width: 767px) {
  .carouselSubtitle .year {
    font-size: 100px;
  }
}

.carouselSubtitle .year {
  color: #eaa12e;
  line-height: normal;
}


.custom-caption-style {
  position: unset;
  z-index: 10;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}


.carousel-caption {
  padding-top: 0;
}


/* Feature Item */
@media (min-width: 767px) {
  .featuredItem .right {
    order: 1;
  }
}

.featuredItem .right .fiTitle {
  left: 110px;
}


.featuredItem .fiTitle {
  position: absolute;
  top: -24px;
  left: -30px;
  width: 80%;
}


.column-image {
  padding: 0px;
  max-width: 25rem;
  /* background-color: #fff !important; */
  border-radius: 0;
}


@media screen and (max-width: 767px) {
  .featuredItem .fiTitle:first-child {
    position: absolute;
  }
}


.featuredItem .fiTitle .longArrow {
  width: 25px;
  height: 20px;
}


.featuredItem .fiTitle {
  background-color: #24a2c4;
  border-radius: 0;
}


.fiTitle {
  display: flex;
}


.fiTitle a {
  color: white;
}


.fiTitle a:hover {
  text-decoration: underline;
}

.feauture-column-text{
  text-align: center;
  font-style: normal;
  padding: 2rem !important;
  text-align: initial;
  margin: inherit;
}

.column-text {
  text-align: center;
  font-style: normal;
  padding: 2rem;
  text-align: initial;
  margin: 20px, 0px;
}
.imagelogopos{
  position: relative;
  left:60%;
  top:15px;
}


.column-text ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}


.featuredItem .fiTitle p {
  color: white;
  margin-left: 10px;
  text-align: left;
}


/* custom-header */
.customHeader ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}


.customHeader span {
  display: block;
}


.chBlue {
  background: #1d5b6b;
}


.chBlue .title,
.chBlue .description {
  color: white;
}

.chWhite .title {
  color: #1d5b6b;
}


.chWhite .description {
  color: black;
}


.chWhite .description li {
  position: relative;
}


.chWhite .description li::before {
  content: "\2022";
  color: #7ac2bf;
  position: absolute;
  left: -18px;
}


.chWhite .description ul {
  list-style: none;
}


/* ch react bootstrap */
.btn-chBlue {
  background-color: white;
  color: #1d5b6b;
}


.btn-chBlue.active {
  color: white;
  background-color: #1d5b6b;
}


.btn-chWhite {
  background-color: #1d5b6b;
  color: white;
}

/* style to remove after switching every page */
.oldfi .column-image .card-header {
  position: absolute;
  top: -24px;
  left: -55px;
  width: 80%;
  background-color: #24a2c4;
  border-radius: 0;
}


.oldfi .column-image .card-header.lftAlmnt:first-child {
  left: 150px;
}


.oldfi .column-image .card-header {
  color: white;
}

.accordion-list-style {
  list-style-type: initial;
  list-style-position: inside;
  margin-bottom: 0.45rem;
}

.accordion .accordion-item .title {
  background: #eaa12e;
  color: #fff;
  font-weight: bold;
  font-size: larger;
}


.accordion {
  border: none;
}


.accordion .accordion-item {
  margin: 0.45rem;
}


.accordion .accordion-item.active .title {
  background: #eaa12e;
  color: #fff;
}


.accordion .accordion-item.active .panel {
  background: #fff2dc;
}


.accordion .accordion-item.active .title:after {
  content: "\2582";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 16px;
}


.accordion .accordion-item .title:after {
  content: "\002B";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 26px;
}


.accordion .accordion-item:first-child .title,
.accordion .accordion-item:last-child .title {
  border-radius: 0;
}

/* social module */
#socialModule {
  background-color: #eaa12e;
}


/* ImageGallery */
.imageGallery {
  margin-left: 15%;
  margin-right: 15%;
}


.galleryItem {
  align-self: center;
}


.categoryButtons .toggleButton {
  border-radius: 0;
  margin-left: -1px;
  width: 250px;
  font-size: 12px;
  font-family: AvenirRoman;
  font-weight: bold;
}


.ril-caption {
  width: 100%;
  text-align: center;
}


.ril__navButtonNext {
  right: 13%;
}


.ril__navButtonPrev {
  left: 13%;
}


@media screen and (max-width: 1023px) {
  .ril__navButtonNext {
    right: 7%;
  }


  .ril__navButtonPrev {
    left: 7%;
  }
}


@media screen and (max-width: 767px) {
  .ril__navButtonNext {
    right: 0;
  }


  .ril__navButtonPrev {
    left: 0;
  }
}


.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}


.ril-image-current {
  max-height: 300px;
}

.ril-image-next,
.ril-image-prev {
  display: none;
}


.pdf .fa-file-pdf {
  color: red;
}


.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}


/* accordion */
.accordion-description ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}


@media screen and (max-width: 768px) {
  .container {
    padding: 0 0;
  }
}


/* header */

.position-static.show.dropdown.nav-item:after {
  content: "\25B2";
  position: absolute;
  color: white;
}


.navbar-dark .navbar-nav .nav-link {
  color: white;
}


#NavBar {
  font-family: AvenirRoman;
  text-transform: uppercase;
  /* padding: 10px 0; */
}


#NavBar .navbar-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #eaa12e;
}


#NavBar .navbar-nav .nav-item .dropdown-item.active {
  color: white;
  background-color: #eaa12e;
}


a.nav-link {
  color: white;
}


#NavBar .dropdown-item {
  font-family: AvenirRoman;
  color: #eaa12e;
  font-weight: bold;
  margin-right: 15px;
  max-width: max-content;
}


#NavBar a.nav-link {
  padding-left: 6px;
  padding-right: 6px;
}


.dropdown-menu.show {
  padding: 10px 100px;
  justify-content: center;
  display: flex;
  text-align: center;
}


.dropdown-menu {
  position: absolute;
  width: 100%;
  box-shadow: 0px 15px 10px -15px #111;
  border: none;
}

.hero-text-hide {
  display: none;
}

@media screen and (max-width: 1199px) {
  .dropdown-menu.show {
    padding: 0;
    display: block;
  }


  .dropdown-menu {
    top: auto;
    left: auto;
    width: auto;
  }


  a.nav-link {
    padding-left: 6px;
    color: white;
  }


  #NavBar {
    background-color: #992c37 !important;
  }

  .mobilemenu-submenu-style .navbar-toggler {
    /* match the same height as logo */
    height: 55px;
  }
}


/* notice */
.notice {
  margin: 20px auto 0px;
}


.underline {
  text-decoration: underline;
}


/* table */
.tableSlice {
  margin: auto;
  width: 85%;
}


/* textblock */
.textBlock {
  font-size: 18px;
  line-height: 20px;
}


/* news articles */
div#news_article b {
  font-weight: bold;
}


div#news_article li {
  font-size: 18px;
  font-family: AvenirRoman;
}


div#news_article p {
  margin-bottom: 1em;
}


/* yellowHeader */
.yellowTitle {
  color: #eaa12e;
  text-align: center;
  margin-top: 100px;
}

/* contacts */
.flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
}

.contactLeft {
  text-align: center;
  padding: 3em 0;
}

.flex-child {
  flex: 1;
}

.btn-group-vertical {
  margin-top: 2em;
}

.linkColor>p>a {
  color: rgb(153, 44, 55);
}

.whiteText>p {
  color: white;
}

.contact-custom-card-style {
  background-color: #eaa12e;
  text-align: center;
  padding: 4.25rem;
}

.contact-sidebar-subheader {
  color: dimgray;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* colors */
.primary-red {
  color: #992c37;
}


.primary-blue {
  color: #1d5b6b;
}

.primary-light-blue {
  color: #277b77;
}

.primary-yellow {
  color: #eaa12e;
}

.secondary-red {
  color: #e05859;
}

.secondary-blue {
  color: #24a2c4;
}

.secondary-light-blue {
  color: #7ac2bf;
}

.secondary-yellow {
  color: #ce6b2e;
}

/* fonts */
@font-face {
  font-family: TrendSansFour;
  src: url(../../fonts/TrendSans-Four.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: TrendSansOne;
  src: url(../../fonts/TrendSans-One.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: AvenirBlack;
  src: url(../../fonts/Avenir-Black.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: AvenirMedium;
  src: url(../../fonts/Avenir-Medium.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: AvenirRoman;
  src: url(../../fonts/Avenir-Roman.woff2) format("woff2");
  font-display: swap;
}

strong {
  font-weight: bold;
}

h1 {
  font-family: TrendSansFour;
  line-height: normal;
  /* hero title */
  text-shadow: 2px 2px 4px #000000;
  font-weight: 400;
}

h2,
h3,
h4 {
  font-family: TrendSansOne;
  line-height: normal;
}

h5,
.label {
  font-family: AvenirBlack;
  line-height: normal;
}

.caption {
  font-family: AvenirMedium;
  line-height: normal;
}

.pLarge {
  font-family: AvenirRoman;
  line-height: normal;
}

p,
.sliceContent li {
  font-family: AvenirRoman;
}

.pSmall {
  font-family: AvenirRoman;
  line-height: normal;
}

/* Desktop */
h1,
h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

h4,
h5 {
  font-size: 28px;
}

.label {
  font-size: 18px;
}

.caption,
.pSmall {
  font-size: 16px;
}

.pLarge {
  font-size: 28px;
}

p,
.sliceContent li {
  font-size: 18px;
  line-height: 20px;
}

/* tablet */
@media screen and (max-width: 1023px) {

  h1,
  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 32px;
  }

  h4,
  h5 {
    font-size: 24px;
  }

  .label,
  .pLarge {
    font-size: 18px;
  }

  .caption,
  .pSmall {
    font-size: 16px;
  }

  p,
  .sliceContent li {
    font-size: 14px;
    line-height: normal;
  }
}

/* mobile */
@media screen and (max-width: 767px) {

  h1,
  h2 {
    font-size: 32px;
  }

  h3,
  .label {
    font-size: 28px;
  }

  h4,
  h5 {
    font-size: 25px;
  }

  .caption {
    font-size: 14px;
  }

  .pLarge {
    font-size: 18px;
  }

  p,
  .sliceContent li {
    font-size: 16px;
    line-height: normal;
  }

  .pSmall {
    font-size: 13px;
  }

  /* chrome */
  body::-webkit-scrollbar {
    display: none;
  }

  /* safari */
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* hero */
.headerPadding {
  padding-top: 86px;
}

/* Carousal */
.carouselContainer {
  box-sizing: content-box;
  margin-top: 0;
}

.blueline {
  padding: 0;
  vertical-align: top;
  background: #7ac2bf;
  margin-top: 16px;
  height: 6px;
}

.carouselText {
  text-align: center;
}

.carouselText ul {
  text-align: left;
}

/* .carousel .carousel-control-prev,
.carousel .carousel-control-next {
  bottom: 81%;
} */
.carousel {
  background: #1d5b6b;
  padding-top: 30px;
  padding-bottom: 30px;
}

.carouselTitle {
  color: darkgray;
  text-align: left;
  max-width: fit-content;
}

.carouselSubtitle [class="20px"] {
  font-size: 20px;
}

.carouselSubtitle .year {
  font-size: 200px;
}

@media screen and (max-width: 1023px) {
  .carouselSubtitle .year {
    font-size: 150px;
  }
}

@media screen and (max-width: 767px) {
  .carouselSubtitle .year {
    font-size: 100px;
  }
}

.carouselSubtitle .year {
  color: #eaa12e;
  line-height: normal;
}

.custom-caption-style {
  position: unset;
  z-index: 10;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.carousel-caption {
  padding-top: 0;
}

/* Feature Item */
@media (min-width: 767px) {
  .featuredItem .right {
    order: 1;
  }
}

.featuredItem .right .fiTitle {
  left: 110px;
}

.featuredItem .fiTitle {
  position: absolute;
  top: -24px;
  left: -30px;
  width: 80%;
}

.column-image {
  padding: 0px;
  max-width: 25rem;
  /* background-color: #fff !important; */
  border-radius: 0;
}

@media screen and (max-width: 767px) {
  .featuredItem .fiTitle:first-child {
    position: absolute;
  }
}

.featuredItem .fiTitle .longArrow {
  width: 25px;
  height: 20px;
}

.featuredItem .fiTitle {
  background-color: #24a2c4;
  border-radius: 0;
}

.fiTitle {
  display: flex;
}

.fiTitle a {
  color: white;
}

.fiTitle a:hover {
  text-decoration: underline;
}

.column-text {
  text-align: center;
  font-style: normal;
  padding: 2rem;
  text-align: initial;
  margin: 20px, 0px;
}

.column-text ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.featuredItem .fiTitle p {
  color: white;
  margin-left: 10px;
}

/* custom-header */
.customHeader ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.customHeader span {
  display: block;
}

.chBlue {
  background: #1d5b6b;
}

.chBlue .title,
.chBlue .description {
  color: white;
}

.chWhite .title {
  color: #1d5b6b;
}

.chWhite .description {
  color: black;
}

.chWhite .description li {
  position: relative;
}

.chWhite .description li::before {
  content: "\2022";
  color: #7ac2bf;
  position: absolute;
  left: -18px;
}

.chWhite .description ul {
  list-style: none;
}

/* ch react bootstrap */
.btn-chBlue {
  background-color: white;
  color: #1d5b6b;
}

.btn-chBlue.active {
  color: white;
  background-color: #1d5b6b;
}

.btn-chWhite {
  background-color: #1d5b6b;
  color: white;
}

/* style to remove after switching every page */
.oldfi .column-image .card-header {
  position: absolute;
  top: -24px;
  left: -55px;
  width: 80%;
  background-color: #24a2c4;
  border-radius: 0;
}

.oldfi .column-image .card-header.lftAlmnt:first-child {
  left: 150px;
}

.oldfi .column-image .card-header {
  color: white;
}

.accordion-list-style {
  list-style-type: initial;
  list-style-position: inside;
  margin-bottom: 0.45rem;
}

.accordion .accordion-item .title {
  background: #eaa12e;
  color: #fff;
  font-weight: bold;
  font-size: larger;
}

.accordion {
  border: none;
}

.accordion .accordion-item {
  margin: 0.45rem;
}

.accordion .accordion-item.active .title {
  background: #eaa12e;
  color: #fff;
}

.accordion .accordion-item.active .panel {
  background: #fff2dc;
}

.accordion .accordion-item.active .title:after {
  content: "\2582";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 16px;
}

.accordion .accordion-item .title:after {
  content: "\002B";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 26px;
}

.accordion .accordion-item:first-child .title,
.accordion .accordion-item:last-child .title {
  border-radius: 0;
}

/* social module */
#socialModule {
  background-color: #eaa12e;
}

/* ImageGallery */
.imageGallery {
  margin-left: 15%;
  margin-right: 15%;
}

.galleryItem {
  align-self: center;
}

.categoryButtons .toggleButton {
  border-radius: 0;
  margin-left: -1px;
  width: 250px;
  font-size: 12px;
  font-family: AvenirRoman;
  font-weight: bold;
}

.ril-caption {
  width: 100%;
  text-align: center;
}

.ril__navButtonNext {
  right: 13%;
}

.ril__navButtonPrev {
  left: 13%;
}

@media screen and (max-width: 1023px) {
  .ril__navButtonNext {
    right: 7%;
  }

  .ril__navButtonPrev {
    left: 7%;
  }
}

@media screen and (max-width: 767px) {
  .ril__navButtonNext {
    right: 0;
  }

  .ril__navButtonPrev {
    left: 0;
  }
}

.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}

.ril-image-current {
  max-height: 300px;
}

.ril-image-next,
.ril-image-prev {
  display: none;
}

.pdf .fa-file-pdf {
  color: red;
}

.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}

/* accordion */
.accordion-description ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 0;
  }
}

/* header */

.position-static.show.dropdown.nav-item:after {
  content: "\25B2";
  position: absolute;
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

#NavBar {
  font-family: AvenirRoman;
  text-transform: uppercase;
  /* padding: 10px 0; */
}

#NavBar .navbar-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #eaa12e;
}

#NavBar .navbar-nav .nav-item .dropdown-item.active {
  color: white;
  background-color: #eaa12e;
}

a.nav-link {
  color: white;
}

#NavBar .dropdown-item {
  font-family: AvenirRoman;
  color: #eaa12e;
  font-weight: bold;
  margin-right: 15px;
  max-width: max-content;
}

#NavBar a.nav-link {
  padding-left: 6px;
  padding-right: 6px;
}

.dropdown-menu.show {
  padding: 10px 100px;
  justify-content: center;
  display: flex;
  text-align: center;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  box-shadow: 0px 15px 10px -15px #111;
  border: none;
}

.hero-text-hide {
  display: none;
}

@media screen and (max-width: 1199px) {
  .dropdown-menu.show {
    padding: 0;
    display: block;
  }

  .dropdown-menu {
    top: auto;
    left: auto;
    width: auto;
  }

  a.nav-link {
    padding-left: 6px;
    color: white;
  }

  #NavBar {
    background-color: #992c37 !important;
  }

  .mobilemenu-submenu-style .navbar-toggler {
    /* match the same height as logo */
    height: 55px;
  }
}

/* notice */
.notice {
  margin: 20px auto 0px;
}

.underline {
  text-decoration: underline;
}

/* table */
.tableSlice {
  margin: auto;
  width: 85%;
}

/* textblock */
.textBlock {
  font-size: 18px;
  line-height: 20px;
}

/* news articles */
div#news_article b {
  font-weight: bold;
}

div#news_article li {
  font-size: 18px;
  font-family: AvenirRoman;
}

div#news_article p {
  margin-bottom: 1em;
}

/* yellowHeader */
.yellowTitle {
  color: #eaa12e;
  text-align: center;
  margin-top: 100px;
}

/* contacts */
.flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
}

.contactLeft {
  text-align: center;
  padding: 3em 0;
}

.flex-child {
  flex: 1;
}

.btn-group-vertical {
  margin-top: 2em;
}

.linkColor>p>a {
  color: rgb(153, 44, 55);
}

.whiteText>p {
  color: white;
}

.contact-custom-card-style {
  background-color: #eaa12e;
  text-align: center;
  padding: 4.25rem;
}

.contact-sidebar-subheader {
  color: dimgray;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* colors */
.primary-red {
  color: #992c37;
}


.primary-blue {
  color: #1d5b6b;
}



.primary-light-blue {
  color: #277b77;
}



.primary-yellow {
  color: #eaa12e;
}



.secondary-red {
  color: #e05859;
}



.secondary-blue {
  color: #24a2c4;
}



.secondary-light-blue {
  color: #7ac2bf;
}



.secondary-yellow {
  color: #ce6b2e;
}



/* fonts */
@font-face {
  font-family: TrendSansFour;
  src: url(../../fonts/TrendSans-Four.woff2) format("woff2");
  font-display: swap;
}



@font-face {
  font-family: TrendSansOne;
  src: url(../../fonts/TrendSans-One.woff2) format("woff2");
  font-display: swap;
}



@font-face {
  font-family: AvenirBlack;
  src: url(../../fonts/Avenir-Black.woff2) format("woff2");
  font-display: swap;
}



@font-face {
  font-family: AvenirMedium;
  src: url(../../fonts/Avenir-Medium.woff2) format("woff2");
  font-display: swap;
}



@font-face {
  font-family: AvenirRoman;
  src: url(../../fonts/Avenir-Roman.woff2) format("woff2");
  font-display: swap;
}



strong {
  font-weight: bold;
}



h1 {
  font-family: TrendSansFour;
  line-height: normal;
  /* hero title */
  text-shadow: 2px 2px 4px #000000;
  font-weight: 400;
}



h2,
h3,
h4 {
  font-family: TrendSansOne;
  line-height: normal;
}



h5,
.label {
  font-family: AvenirBlack;
  line-height: normal;
}



.caption {
  font-family: AvenirMedium;
  line-height: normal;
}



.pLarge {
  font-family: AvenirRoman;
  line-height: normal;
}



p,
.sliceContent li {
  font-family: AvenirRoman;
}



.pSmall {
  font-family: AvenirRoman;
  line-height: normal;
}

/* Desktop */
h1,
h2 {
  font-size: 48px;
}



h3 {
  font-size: 36px;
}



h4,
h5 {
  font-size: 28px;
}



.label {
  font-size: 18px;
}



.caption,
.pSmall {
  font-size: 16px;
}



.pLarge {
  font-size: 28px;
}



p,
.sliceContent li {
  font-size: 18px;
  line-height: 20px;
}



/* tablet */
@media screen and (max-width: 1023px) {



  h1,
  h2 {
    font-size: 40px;
  }



  h3 {
    font-size: 32px;
  }



  h4,
  h5 {
    font-size: 24px;
  }



  .label,
  .pLarge {
    font-size: 18px;
  }



  .caption,
  .pSmall {
    font-size: 16px;
  }



  p,
  .sliceContent li {
    font-size: 14px;
    line-height: normal;
  }
}



/* mobile */
@media screen and (max-width: 767px) {



  h1,
  h2 {
    font-size: 32px;
  }



  h3,
  .label {
    font-size: 28px;
  }



  h4,
  h5 {
    font-size: 25px;
  }



  .caption {
    font-size: 14px;
  }



  .pLarge {
    font-size: 18px;
  }



  p,
  .sliceContent li {
    font-size: 16px;
    line-height: normal;
  }



  .pSmall {
    font-size: 13px;
  }

  /* chrome */
  body::-webkit-scrollbar {
    display: none;
  }



  /* safari */
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* hero */
.headerPadding {
  padding-top: 86px;
}



/* Carousal */
.carouselContainer {
  box-sizing: content-box;
  margin-top: 0;
}



.blueline {
  padding: 0;
  vertical-align: top;
  background: #7ac2bf;
  margin-top: 16px;
  height: 6px;
}



.carouselText {
  text-align: center;
}



.carouselText ul {
  text-align: left;
}



/* .carousel .carousel-control-prev,
.carousel .carousel-control-next {
  bottom: 81%;
} */
.carousel {
  background: #1d5b6b;
  padding-top: 30px;
  padding-bottom: 30px;
}



.carouselTitle {
  color: darkgray;
  text-align: left;
  max-width: fit-content;
}



.carouselSubtitle [class="20px"] {
  font-size: 20px;
}



.carouselSubtitle .year {
  font-size: 200px;
}



@media screen and (max-width: 1023px) {
  .carouselSubtitle .year {
    font-size: 150px;
  }
}



@media screen and (max-width: 767px) {
  .carouselSubtitle .year {
    font-size: 100px;
  }
}

.carouselSubtitle .year {
  color: #eaa12e;
  line-height: normal;
}



.custom-caption-style {
  position: unset;
  z-index: 10;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}



.carousel-caption {
  padding-top: 0;
}



/* Feature Item */
@media (min-width: 767px) {
  .featuredItem .right {
    order: 1;
  }
}

.featuredItem .right .fiTitle {
  left: 110px;
}



.featuredItem .fiTitle {
  position: absolute;
  top: -24px;
  left: -30px;
  width: 80%;
}



.column-image {
  padding: 0px;
  max-width: 25rem;
  /* background-color: #fff !important; */
  border-radius: 0;
}



@media screen and (max-width: 767px) {
  .featuredItem .fiTitle:first-child {
    position: absolute;
  }
}



.featuredItem .fiTitle .longArrow {
  width: 25px;
  height: 20px;
}



.featuredItem .fiTitle {
  background-color: #24a2c4;
  border-radius: 0;
}



.fiTitle {
  display: flex;
}



.fiTitle a {
  color: white;
}



.fiTitle a:hover {
  text-decoration: underline;
}



.column-text {
  text-align: center;
  font-style: normal;
  padding: 2rem;
  text-align: initial;
  margin: 20px, 0px;
}



.column-text ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}



.featuredItem .fiTitle p {
  color: white;
  margin-left: 10px;
}



/* custom-header */
.customHeader ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}



.customHeader span {
  display: block;
}



.chBlue {
  background: #1d5b6b;
}



.chBlue .title,
.chBlue .description {
  color: white;
}

.chWhite .title {
  color: #1d5b6b;
}



.chWhite .description {
  color: black;
}



.chWhite .description li {
  position: relative;
}



.chWhite .description li::before {
  content: "\2022";
  color: #7ac2bf;
  position: absolute;
  left: -18px;
}



.chWhite .description ul {
  list-style: none;
}



/* ch react bootstrap */
.btn-chBlue {
  background-color: white;
  color: #1d5b6b;
}



.btn-chBlue.active {
  color: white;
  background-color: #1d5b6b;
}



.btn-chWhite {
  background-color: #1d5b6b;
  color: white;
}

/* style to remove after switching every page */
.oldfi .column-image .card-header {
  position: absolute;
  top: -24px;
  left: -55px;
  width: 80%;
  background-color: #24a2c4;
  border-radius: 0;
}



.oldfi .column-image .card-header.lftAlmnt:first-child {
  left: 150px;
}



.oldfi .column-image .card-header {
  color: white;
}

.accordion-list-style {
  list-style-type: initial;
  list-style-position: inside;
  margin-bottom: 0.45rem;
}

.accordion .accordion-item .title {
  background: #eaa12e;
  color: #fff;
  font-weight: bold;
  font-size: larger;
}



.accordion {
  border: none;
}



.accordion .accordion-item {
  margin: 0.45rem;
}



.accordion .accordion-item.active .title {
  background: #eaa12e;
  color: #fff;
}



.accordion .accordion-item.active .panel {
  background: #fff2dc;
}



.accordion .accordion-item.active .title:after {
  content: "\2582";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 16px;
}



.accordion .accordion-item .title:after {
  content: "\002B";
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 26px;
}



.accordion .accordion-item:first-child .title,
.accordion .accordion-item:last-child .title {
  border-radius: 0;
}

/* social module */
#socialModule {
  background-color: #eaa12e;
}



/* ImageGallery */
.imageGallery {
  margin-left: 15%;
  margin-right: 15%;
}



.galleryItem {
  align-self: center;
}



.categoryButtons .toggleButton {
  border-radius: 0;
  margin-left: -1px;
  width: 250px;
  font-size: 12px;
  font-family: AvenirRoman;
  font-weight: bold;
}



.ril-caption {
  width: 100%;
  text-align: center;
}



.ril__navButtonNext {
  right: 13%;
}



.ril__navButtonPrev {
  left: 13%;
}



@media screen and (max-width: 1023px) {
  .ril__navButtonNext {
    right: 7%;
  }



  .ril__navButtonPrev {
    left: 7%;
  }
}



@media screen and (max-width: 767px) {
  .ril__navButtonNext {
    right: 0;
  }



  .ril__navButtonPrev {
    left: 0;
  }
}



.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}



.ril-image-current {
  max-height: 300px;
}

.ril-image-next,
.ril-image-prev {
  display: none;
}



.pdf .fa-file-pdf {
  color: red;
}



.ril__caption h1,
.ril__caption h2,
.ril__caption h3,
.ril__caption h4,
.ril__caption h5 {
  color: #1d5b6b;
  text-align: center;
}



/* accordion */
.accordion-description ul {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}



@media screen and (max-width: 768px) {
  .container {
    padding: 0 0;
  }
}



/* header */

.position-static.show.dropdown.nav-item:after {
  content: "\25B2";
  position: absolute;
  color: white;
}



.navbar-dark .navbar-nav .nav-link {
  color: white;
}



#NavBar {
  font-family: AvenirRoman;
  text-transform: uppercase;
  /* padding: 10px 0; */
}



#NavBar .navbar-nav .nav-item .nav-link[aria-expanded="true"] {
  color: #eaa12e;
}



#NavBar .navbar-nav .nav-item .dropdown-item.active {
  color: white;
  background-color: #eaa12e;
}



a.nav-link {
  color: white;
}



#NavBar .dropdown-item {
  font-family: AvenirRoman;
  color: #eaa12e;
  font-weight: bold;
  margin-right: 15px;
  max-width: max-content;
}



#NavBar a.nav-link {
  padding-left: 6px;
  padding-right: 6px;
}



.dropdown-menu.show {
  padding: 10px 100px;
  justify-content: center;
  display: flex;
  text-align: center;
}



.dropdown-menu {
  position: absolute;
  width: 100%;
  box-shadow: 0px 15px 10px -15px #111;
  border: none;
}

.hero-text-hide {
  display: none;
}

@media screen and (max-width: 1199px) {
  .dropdown-menu.show {
    padding: 0;
    display: block;
  }



  .dropdown-menu {
    top: auto;
    left: auto;
    width: auto;
  }



  a.nav-link {
    padding-left: 6px;
    color: white;
  }



  #NavBar {
    background-color: #992c37 !important;
  }

  .mobilemenu-submenu-style .navbar-toggler {
    /* match the same height as logo */
    height: 55px;
  }
}



/* notice */
.notice {
  margin: 20px auto 0px;
}



.underline {
  text-decoration: underline;
}



/* table */
.tableSlice {
  margin: auto;
  width: 85%;
}

/* textblock */
.textBlock {
  font-size: 18px;
  line-height: 20px;
}

/* news articles */
div#news_article b {
  font-weight: bold;
}

div#news_article li {
  font-size: 18px;
  font-family: AvenirRoman;
}

div#news_article p {
  margin-bottom: 1em;
}

/* yellowHeader */
.yellowTitle {
  color: #eaa12e;
  text-align: center;
  margin-top: 100px;
}

/* contacts */
.flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
}

.contactLeft {
  text-align: center;
  padding: 3em 0;
}

.flex-child {
  flex: 1;
}

.btn-group-vertical {
  margin-top: 2em;
}

.linkColor>p>a {
  color: rgb(153, 44, 55);
}

.whiteText>p {
  color: white;
}

.contact-custom-card-style {
  background-color: #eaa12e;
  text-align: center;
  padding: 4.25rem;
}

.contact-sidebar-subheader {
  color: dimgray;
  font-weight: bold !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselType2Text
{
  text-align: left;
  padding:none;
  text-align: justify;

}
.carouselType2Text ul {
  text-align: left;
}
p, 
.carouselType2Text {
  font-size:16px !important;
}
.rightAlign{
  text-align: right;
}
.leftAlign{
  text-align: left;
}
.carousel.text-center.community-matters span.carousel-control-next-icon, .carousel.text-center.community-matters span.carousel-control-prev-icon{
background-color: #000000;
}
.community-matters-carousel4 a.carousel-control-next, .community-matters-carousel4 a.carousel-control-prev {
    top: 140px !important;
}
.cardcomm{
  border: 0;
  font-weight: 400;
}
.bordDirectorsDiv{
  font-size: 30px;
  color: #ffff;  
  width: 60%;  
  /* padding: 10px; */
  background-color: #00abd6;
  /* border-radius: 8px; */
  text-align: center;
  margin: auto;
  text-shadow: 2px 2px 0 #bdc3c7;
  font-family: sans-serif;
  margin-top: 50px;
  margin-bottom: 50px;
}