.media-page-content > .bg-chiliBrandCol {
  background-color: #992c37;
  padding: 1.5rem 0.5rem;
  box-shadow: none;
}
.d-flex.justify-content-center.mediasubnav-style {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-weight: 300;
}
.small-12 {
  width: 100%;
}
@media screen and (min-width: 46.875em) {
  .column,
  .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
.wd_newsfeed_releases .wd_item_list {
  text-align: left !important;
}
ul.wd_layout-simple {
  padding: 0;
  margin: 10px 0;
  list-style: none;
}
.wd_item {
  overflow: auto;
}
.wd_item {
  margin: 0 0 15px 0 !important;
}

li {
  font-size: inherit;
}
.wd_item_wrapper:first-child,
.wd_layout-masonry .wd_item_wrapper,
.wd_layout-grid .wd_item_wrapper {
  margin-left: 0;
}
.wd_item_wrapper {
  margin-left: 149px;
}
.wd_item_wrapper {
  margin-left: 0 !important;
}
.wd_item .wd_date {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.167em;
  color: #cccccc;
  margin: 0 0 15px 0 !important;
}
.wd_date {
  font-size: 1em !important;
}
.wd_date {
  color: darkgray !important;
}
.wd_title {
  font-size: 1.2em !important;
  font-weight: normal !important;
}

/* #wd_printable_content a {
  color: #1d5b6b;
  font-weight: bold;
  margin: 0 0 15px 0 !important;
  line-height: 25px;
  padding: 0;
} */
.wd_title a {
  text-decoration: none !important;
}

.wd_item .wd_subtitle {
  font-weight: normal !important;
  margin-top: 2px !important;
}
.wd_hidden {
  display: none;
}
.btn.btn-lg {
  width: 22%;
}

@media screen and (max-width: 46.8125em) {
  .btn-group {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
  }

  .btn-group-vertical-custom {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1023px) {
  .btn-group {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
  }

  .btn-group-vertical-custom {
    align-items: center;
    justify-content: center;
  }
}

button.btn.btn-primary.btn-lg {
  background-color: #1d5b6b !important;
}
.btn-default {
  background-color: #fff !important;
  color: #000;
}

ul li.wd_item:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin: 15px 0 15px 0;
  background: #ccc;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  background-color: #000;
  color: #000;
}
.readMoreSty {
  color: #24a2c4 !important;
  font-weight: bold;
  text-decoration: underline !important;
}
.readMoreSty:hover {
  color: #1d5b6b !important;
  font-weight: bold;
  text-decoration: underline !important;
}
.nav-link {
  display: block;
}

div#mediaroom-subnavbar:before {
  display: table;
  content: "";
  width: 24px;
  height: 13px;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 25px 25px;
  border-color: transparent transparent #fff transparent;
  left: 488px;
  bottom: 35px;
}
.media-nav-style.nav-style div#mediaroom-subnavbar:before {
  left: 680px;
}
.categoryactivestate,
.categoryhoverstate:hover,
.categoryhoverstate:active,
.categoryhoverstate:focus {
  background-color: #1d5b6b !important;
  color: #fff !important;
  border: 0;
}
.btn-outline-default {
  border: 2px solid lightgrey !important;
  color: #808080 !important;
  box-shadow: none;
  font-weight: bold;
}
#search-style,
#sort-style {
  cursor: pointer;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  font-weight: bold;
}
.pagination > li {
  display: inline;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  /* color: #1d5b6b !important; */
  color: #24a2c4 !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #d0d0d0;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 3;
  color: #0056b3;
  background-color: #eee;
  border-color: #ddd;
  font-weight: bold;
}
button.btn.btn-outline-default.btn-lg {
  color: #2a283d;
  font-weight: bold;
}
#sort-style {
  align-items: inherit;
}
.categoryhoverstate:hover,
.categoryhoverstate:active,
.categoryhoverstate:focus {
  color: #fff;
}
.DISPLAY-NONE-CLASS {
  display: none;
}

/* .search_input:focus {
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
} */

.pagination .disabled {
  color: #979797;
  cursor: not-allowed;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.ligthboxoverlay {
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%; */
  padding-top: 7px;
  padding-right: 7px;
  position: absolute;
  right: 200px;
  top: 35px;
  opacity: 0;
  transition: 0.3s ease;
}

.image-box:hover .ligthboxoverlay {
  opacity: 1;
  display: block;
}
.lightboxicon {
  color: white;
  font-size: 30px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.fa-download:hover {
  color: #007bff !important;
  z-index: 1140;
}
.fa-download {
  color: #33b5e5 !important;
  z-index: 1140;
  font-size: 0.75em;
}
span.wd_attachment_title:hover {
  color: #007bff !important;
  text-decoration: underline;
}

.image-box {
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding: 15px;
  width: 100%;
  max-width: 650px;
  margin: auto !important;
  text-align: left;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 0px 0px 0px 0px;
}
.ovrlayimagebox {
  flex: 1 1;
  order: 1;
  position: relative;
}
.ovrlayimagebox {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sticky.is-stuck.is-at-top {
  top: 0;
}

.sticky.is-stuck {
  margin-top: 0 !important;
  width: 100% !important;
}
.sticky.is-stuck {
  position: fixed;
  z-index: 5;
}
.sticky-container {
  z-index: 1000;
  position: relative;
  height: 143px;
}

.top-bar::before,
.top-bar::after {
  content: " ";
  display: table;
}
.small-12 {
  width: 100%;
}
.bg-chiliBrandCol {
  background-color: #992c37;
  padding-top: 1.5rem;
  box-shadow: none;
}
.mediasubnav-style {
  background-color: #fff;
}

.item_name a {
  display: block;
  color: #ee2d24 !important;
}

#wd_printable_content a:hover {
  color: #ee2d24;
}
a.download_leader_link.itemlink {
  color: #000;
}
button.btn.btn-outline-default.btn-lg:not([disabled]):not(.disabled):active:focus,
button.btn.btn-outline-default.btn-lg:not([disabled]):not(.disabled):active {
  background-color: #1d5b6b !important;
  border-color: #1d5b6b;
  color: white;
}
.btn-outline-default.active,
.btn-outline-default:active,
.btn-outline-default:focus {
  background-color: #1d5b6b !important;
  color: white !important;
}
.btn-toolbar {
  align-items: center;
  justify-content: center;
}

img.ril-image-current.ril__image {
  transform: translate3d(0px, 0px, 0px) scale3d(0.77083, 0.77083, 1) !important;
}

span.wd_attachment_size {
  display: block;
  width: 200px;
  text-align: center;
  padding: 0.5rem;
  font-weight: normal;
  font-size: 14px;
}
span.wd_attachment_title {
  color: #33b5e5 !important;
  padding-left: 6px;
  font-weight: bold;
}

.blue.lighten-1 {
  background-color: #24a2c4 !important;
}
.btn-outline-default:hover,
.btn-outline-default:focus {
  border-color: #1d5b6b !important;
  background-color: #1d5b6b !important;
  color: #fff !important;
  font-weight: bold;
}
.mt-10,
.my-10 {
  margin-top: 5rem !important;
}
.mt-20 {
  margin-top: 10rem !important;
}

#main-navbar .navbar-nav > li > a.nav-link.active {
  color: #eaa12e !important;
}

.blue-grey-text {
  text-transform: inherit;
  color: #1d5b6b !important;
  text-align: center;
  margin-bottom: 0;
}
.blue-grey-text1 {
  text-transform: inherit;
  color: #1d5b6b !important;
  text-align: center;
}
.captionTitle {
  color: #1d5b6b !important;
  font-weight: 600;
  text-transform: inherit;
  margin-bottom: 10px;
}
.captionName {
  text-transform: inherit;
  color: #1d5b6b !important;
  text-align: center;
  margin-bottom: 10px;
}

.media-nav-style nav.navbar.navbar-expand-lg.navbar-dark {
  background: #ffffff;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
  z-index: 1040;
}
div#mediasubnavId {
  margin: 0 auto;
}
#mediaroom-subnavbar .nav-item .nav-link {
  color: #eaa12e;
  font-weight: bold;
}
#mediaroom-subnavbar .nav-item .nav-link.active {
  color: white;
  font-weight: bold;
  background: #eaa12e;
}
.flex-container-fact {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.fact-postop {
  top: 100px;
}
#dataTble {
  border: 1px solid #000;
  padding: 10px;
}
#dataTble thead {
  background-color: #f44118;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  color: white;
  font-weight: bold;
  padding: 10px;
}
.media-subheader {
  margin-top: 2.25rem;
  line-height: 2;
  font-weight: 600;
  text-align: left;
  width: 120%;
}

.box-media:nth-child(2) {
  align-self: flex-end;
}

.box-media {
  flex: 1 1;
  order: 1;
  position: relative;
}

a.media-title.brinker-feature-title.card-text.custom-tile-title {
  top: -20px;
  right: 0px;
  left: unset;
}

.card.card-media {
  top: 50px;
  right: 40px;
}
.img-gallery {
  max-width: 85%;
  height: auto;
}
p.mediaourstorydesc.card-text {
  text-align: left;
  width: 90% !important;
  margin: 0 auto;
}
.fixed-nav-ld {
  background-color: #992c37;
  /* margin-top: -30px; */
  padding-top: 1rem;
  box-shadow: none;
}
/* .btn-group-lg>.btn {
    font-size: 0.9rem;
    padding: 1rem 1rem;
} */
.mt-8 {
  margin-top: 8rem !important;
}
