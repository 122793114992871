
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 2px;
  font-weight: bold;
  z-index: 5;
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
  font-weight: bold;
}
.nav-link {
  text-transform: uppercase !important;
  font-weight: 700 !important;
}
.chilis.hero {
  background-position: center;
  height: 400px;
}
.hero {
  height: 250px;
  display: table;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.hero-text {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  max-width: 1024px;
  color: #fff;
  z-index: 10;
  position: relative;
  padding-top: 90px; /*size of header */
}
.hero-text p {
  margin-top: 1rem;
  font-weight: 600;
  text-align: left;
}
div#company-trans-subnavbar::before,
div#commitment-trans-subnav::before {
  display: table;
  content: '';
  width: 24px;
  height: 13px;
  position: relative;
  border-style: solid;
  border-width: 0 25px 25px 25px;
  border-color: transparent transparent #fff transparent;
}
div#commitment-trans-subnav::before,
div#company-trans-subnavbar::before {
  bottom: 40px;
}

.fixed-nav {
  background-color: #992c37;
  box-shadow: none;
}
.navbar {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
div#responsive-navbar-nav{
  justify-content: space-between;
}
div#main-navbar{
  background:white
}
#NavBar a{
  color:white;
}
#subNavBar a{
  color: #eaa12e;
}





.nav {
  margin-bottom: 0;
}

.dropdown {
  position: static;
}

.dropdown-menu {
  text-align: center;
}

.dropdown-menu>li {
  display: inline-block;
}

/* .dropdown:hover>.dropdown-menu {
  display:block;
} */

.nav-item.show .nav-link:after {
  transform: rotate(180deg);
}
#NavBar .nav-item.show .nav-link{
  color: orange;
}
#NavBar .container{
  padding-right: 0;
  padding-left: 0;
} 
#NavBar a:hover {
  color: orange;
}