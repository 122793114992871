.wd_newsfeed_releases-detail .wd_title {
  text-rendering: optimizelegibility;
  font-weight: 400;
  font-family: 'HalisR-Bold', Arial, sans-serif;
  text-transform: uppercase;
  font-style: normal;
  text-align: center;
  font-size: 36px !important;
  line-height: 120%;
  margin-bottom: 2rem;
}

.wd_newsfeed_releases-detail .wd_title {
  padding: 0 0 10px;
  color: #1d5b6b;
  white-space: normal;
  word-wrap: break-word;
  margin-top: 90px;
  font-weight: bold !important;
}

.wd_title {
  font-size: 1.3em;
  font-weight: bold;
}

.wd_title {
  font-size: 1.2em !important;
  font-weight: normal !important;
}

.wd_newsfeed_releases-detail .wd_subtitle {
  padding: 10px 0;
}

.wd_subtitle {
  font-weight: bold;
}

.news-asset-wrapper {
  border: 0px none;
  margin: 15px 0 0 0;
  padding: 0 0 14px;
  background: transparent url(./assets_shadow_full.png) center bottom no-repeat;
  clear: both;
}

.news-assset-container {
  background: #fff url(./assets_tab.gif) 0 bottom/contain repeat-x !important;
  margin: 0;
  padding: 0;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-topleft: 4px;
}

.tab-conteiner {
  background: #fff url(./assets_tab.gif) 0 bottom/contain repeat-x !important;
  margin: 0;
  padding: 0;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-topleft: 4px;
}

#wd_printable_content ul {
  text-align: left;
}

.news-tabs {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

.list-news-tab {
  /* float: left; */
  display: block;
}

li {
  font-size: inherit;
}

.news-tabs a {
  float: left;
  border-left: 1px solid #fff;
  border-right: 1px solid #e1e1e1;
  display: block;
  padding: 8px;
  margin: 1px 0 0;
  width: 30%;
}

a:hover,
a:visited {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.news-tabs a .asset_icon {
  color: #4f5051;
  font-size: 1.2em;
  margin: 0 5px 0 0;
}

.asset-gallery,
video-asset-gallery {
  border: 1px solid #ccc;
  border-top: 0;
  height: auto;
  margin-top: -15px;
  padding: 1px;
  display: none;
}

.asset-gallery .images {
  height: 100px;
}

.asset-gallery ul,
video-asset-gallery ul {
  text-align: center !important;
}

.asset-gallery .images ul li {
  list-style: none;
  display: inline;
}

.asset-gallery .images ul li img {
  width: 17px;
  cursor: pointer;
}

div.wd_news_body img {
  margin: 0 0 20px 20px;
  float: right;
  max-width: 250px;
  max-height: 250px;
  width: auto !important;
  height: auto !important;
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

img {
  border: 0;
}

img {
  vertical-align: middle;
}

img {
  border: 0;
}

.gallery-details {
  margin-bottom: 20px;
}

#show_img img {
  max-width: 400px !important;
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

img {
  border: 0;
}

img {
  vertical-align: middle;
}

#wd_printable_content a {
  color: #1d5b6b;
}

.wd_hi_res_link_wrapper a,
.wd_formbuilder_submit,
.more a {
  display: inline-block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0;
  margin: 0 0 1rem 0;
  font-size: 0.9rem;
  background-color: #fff;
  color: #ee2d24 !important;
  text-transform: uppercase;
  border: 2px solid #ee2d24;
  font-family: 'HalisR-Bold', Arial, sans-serif;
  letter-spacing: 0.5px;
  display: inline-block;
  margin-top: 1rem;
  background-color: transparent;
  font-size: 1.25rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-decoration: none;
  outline: 0;
  margin-top: 2rem;
}

.wd_newsfeed_releases-detail .wd_body.wd_news_body {
  text-align: left;
}

.wd_contact {
  margin: 1em 0;
}

#show_img {
  text-align: center;
}

#wd_printable_content a:hover {
  color: #992c37;
  text-decoration: underline !important;
}

.wd_formbuilder_submit:hover,
.wd_hi_res_link_wrapper a:hover,
.more a:hover {
  background-color: #ee2d24;
  color: #fff !important;
  opacity: 1;
  filter: alpha(opacity=100);
  border-color: #fff !important;
}

.close-gallery-details {
  display: none;
}

a.nav-link.gobackbreadcrumb {
  text-align: left;
  font-size: initial;
  color: #24a2c4 !important;
}

.gobackbreadcrumbhover:hover {
  text-decoration: underline;
}

.imgcontainer {
  position: relative;
  width: 100%;
  max-width: 400px;
  display: inline-table;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.imgoverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0, 0, 0, 0.3);
}

.imgcontainer:hover .imgoverlay {
  opacity: 1;
}

.icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.loadingCenter {
  display: block;
  margin: 0 auto;
  text-align: center;
}