.company-page-content .cust-company-subnav a.nav-link.active {
  color: #fff;
  background-color: #eaa12e;
  justify-content: center;
}

.card-title {
  margin-bottom: 0.75rem;
  color: white;
  font-weight: bold;
}


.jumbotron {
  margin-bottom: 0;
  box-shadow: none;
}



.card .card-body .card-text {
  color: #fff;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  font-weight: bold;
  line-height: 2;
}

.company-page-content .nav-style,
.contact-page-content .nav-style,
.nevada-legal-page-content .nav-style {
  width: 100%;
  position: absolute;
  top: 0;
  /* position: sticky;
  position: -webkit-sticky;
  top:0; */
  z-index: 1070;
  left: 0;
  right: 0;
}

.company-page-content .bg-transparent,
.contact-page-content .bg-transparent,
.nevada-legal-page-content .bg-transparent {
  box-shadow: none;
}

.box {
  flex: 1;
  order: 1;
  position: relative;
}

.box:nth-child(2) {
  align-self: flex-end;
  position: relative;
  bottom: 28rem;
}

.box:nth-child(2) .feature-header-item {
  margin-left: 21%;
  position: inherit;
}


.bg-error-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.accordion .accordion-item:first-child .title,
.accordion .accordion-item:last-child .title {
  border-radius: 0;
}


a.home-btn-primary:hover,
a.home-btn-primary:focus,
a.home-btn-primary:visited {
  outline: 0;
  text-decoration: underline !important;
  color: #fff;
  font-weight: bold;
}


.btn-group-lg>.btn {
  font-size: 1rem;
  padding: 1rem 1rem !important;
}

a.brinker-feature-title.card-text.custom-tile-title {
  top: 42px;
  left: 20px;
}


a.brinker-feature-title1.card-text.custom-tile-title-right {
  top: -5px;
  left: 11rem;
}

.hero-text-hide {
  display: none;
}

a.card-text.custom-tile-title-right {
  position: absolute;
  width: 285px;
  left: 17rem;
  background-color: #24a2c4;
  border-radius: 0;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  color: #fff;
  top: 42px;
}


a.media-title.card-text.custom-tile-title-right {
  left: 30px;
  top: 0px;
}


p {
  margin-bottom: 1rem;
  text-rendering: optimizeLegibility;
}

div,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}


*,
*::before,
*::after {
  box-sizing: inherit;
}


p {
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.home-btn-primary {
  background-color: #24a2c4 !important;
  color: #fff;
  padding: 1rem 1.9rem;
  width: 240px;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: bold;
}

.contact-btn-primary {
  background-color: #24a2c4 !important;
  color: #fff !important;
  width: 200px;
  border-radius: 0;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: inline-flex;
  justify-content: center;
  display: inline-flex;
  justify-content: center;
}

.grid {
  width: 73%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}


.grid.special {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}


.topmodule {
  color: #1d5b6b;
  /* position: relative;
  left: 20px; */
}


.company-jumbo {
  box-shadow: none;
}

.ourstorytitle,
.ourstorysubtitle,
.ourstorydesc {
  text-align: left;

  line-height: 1.5;
  word-spacing: normal;
  letter-spacing: inherit;
  font-size: xx-large;
}


.ourstorydesc {
  margin-top: 1rem;
}

.homeclassbody {
  width: 85%;
  margin: 0 auto;
}


.ourstorysubtitle {
  position: relative;
  left: 100px;
}


.home-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 4.25rem;
}


.nevada-marg {
  width: 80%;
  margin: 0 auto;
}