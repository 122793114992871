.page-footer {
  background-color: #2c383c !important;
  padding: 25px;
  width: 100%;
  display: block;
}

.Brinker-Nav-Footer
  .footer-section-social
  section.newsletter
  .Brinker-Newsletter-Signup {
  display: inline-block;
  margin-left: 2rem;
}

.Brinker-Newsletter-Signup {
  display: inline-block;
  position: relative;
  width: auto;
}

.Brinker-Nav-Footer .footer-section-social section.newsletter input {
  background-color: #fff;
  font-family: GothamBold;
  line-height: 2rem;
}
.Brinker-Newsletter-Signup input {
  font-size: 16px;
  padding: 10px 16px;
  height: 50px;
  border: 0;
  border-radius: 10px;
  color: #333;
  background-color: #eee;
  display: block;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 1rem;
  min-width: 16em;
  display: inline-block;
  width: 100%;
  position: relative;
}
.Brinker-Nav-Footer
  .footer-section-social
  section.newsletter
  .Brinker-Newsletter-Signup
  .submit {
  font-size: 28px;
  top: 0.8rem;
}
.Brinker-Newsletter-Signup .submit {
  cursor: pointer;
  color: #992c37;
  position: absolute;
  right: 1rem;
  top: 1.2rem;
}
.Brinker-Nav-Footer .glyphfas:before {
  font-size: 2.2rem;
  line-height: 2.2rem;
}
.Brinker-Nav-Footer .footer-section-social section.newsletter .label {
  font-weight: 400;
  line-height: 1.42;
  font-family: GothamBold, Helvetica, Arial, sans-serif;
}
.signUp:hover {
  color: #fff;
  text-decoration-line: underline !important;
}
a:hover,
a:focus,
a:visited {
  outline: 0;
  text-decoration: underline;
}
@media (min-width: 1200px) {
  .Brinker-Nav-Footer .footer-section-social section.newsletter .label {
    font-size: 16px;
  }
  .Brinker-Nav-Footer .footer-section-social .social-container .blog-wrapper,
  .Brinker-Nav-Footer
    .footer-section-social
    .social-container
    .newsletter-signup,
  .Brinker-Nav-Footer .footer-section-social .social-container .social-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
}

/* .brinker-footer {
  max-width: 85%;
  margin: 0 auto;
} */

table th,
table td {
  padding: 10px;
  border: 1px solid #000000;
  font-weight: 400;
}
table th {
  font-weight: bold;
}
.footerMenu,
.footerAdditionalLinks,
.copyright {
  font-family:AvenirMedium;
}
