html,
body {
  height: 100%;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}
@media screen and (max-width: 46.8125em) {
  .accordion-list-style {
    list-style-type: initial;
    list-style-position: inside;
    line-height: 1.5;
  }
  .company-page-content .bg-transparentl {
    background-color: #992c37;
    padding: 1.5rem 0.5rem;
    box-shadow: none;
  }
  .hero-text {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    max-width: 1024px;
    color: #fff;
    z-index: 10;
    position: relative;
  }
  .mobilemenu-submenu-style {
    background-color: #992c37 !important;
    /* margin-top: -31px; */
    box-shadow: none;
  }
  .carousel-indicators {
    bottom: 15px;
  }
  .dropdown-menu.show {
    border-color: #ae1e18 !important;
    background: white;
    /* top: 0%;
    right: 0; */
    left: auto;
  }
  .sticky-container {
    height: 40px;
  }
  .sticky.is-stuck {
    margin-top: 1rem !important;
  }
  .dropdown-menu.show > a {
    color: #eaa12e !important;
    font-weight: bold !important;
  }

  .navbar .dropdown-menu a,
  a.dropdown-toggle.nav-link,
  .navbar-dark .navbar-nav .nav-link {
    padding: 0.95rem;
    font-size: 15px;
    font-weight: initial;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }
  .navbar-brand {
    /* padding: 1.2rem 1rem; */
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }
  .grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .jumbotron {
    width: 100%;
  }

  .media-card-text {
    width: 88%;
  }

  .container {
    margin: 0 auto;
  }
  figure {
    margin: 0 0 0.5rem;
  }
  .wd_newsfeed_releases-detail .wd_title {
    margin-top: 0px;
  }
  .media-newsrelease-container {
    margin-top: 7rem;
  }

  #sm-hr-line {
    vertical-align: bottom;
    position: relative;
    top: -7px;
  }
  .accordion-header h2 {
    font-size: 2rem;
  }
  .mob-mt-mb {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .footer-logo {
    width: 350px;
  }
  .card-title.h5 {
    width: 100%;
    margin: 0 auto;
    word-spacing: normal;
    letter-spacing: normal;
    line-height: 1;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    font-size: medium;
  }
  .brinker-footer-module {
    min-height: auto;
  }
  ul.subContainer {
    font-weight: 700 !important;
    font-style: normal;
  }
  .card .card-body .card-text {
    font-weight: 700 !important;
    font-style: normal;
  }
  .custom-caption-style {
    margin-top: 0;
  }
  .custom-caption-style h3 {
    color: rgb(228, 166, 49);
    font-size: 3rem;
    font-weight: bold;
    width: 78%;
    margin: 0 auto;
  }
  .column-text-comm {
    text-align: initial;
    font-style: normal;
    font-weight: 700;
    padding: 2rem;
  }
  .home-btn-primary {
    font-weight: 600;
  }
  .brinker-footer-hrline {
    display: none;
  }
  .homeclassbody {
    width: 100%;
  }
  .ourstorytitle,
  .ourstorysubtitle,
  .ourstorydesc {
    line-height: 1.2;
    font-weight: inherit !important;
    color: rgb(228, 166, 49);
  }
  .box:nth-child(2) {
    bottom: 0;
  }
  .postiles1,
  .postiles {
    right: 0;
    left: 0;
  }
  #company-feature-module {
    height: auto;
  }
  .home-card-body {
    padding: 0;
  }

  a.brinker-feature-title1.card-text.custom-tile-title-right {
    top: -20px !important;
    left: 0 !important;
  }
  .grid-leader {
    width: 100%;
  }
}
/*temp css*/
.zoom {
  padding: 50px;
  background-color: green;
  transition: transform 0.2s;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
}
::-moz-selection {
  /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: red;
  background: yellow;
}

.custom-select {
  position: relative;
  height: calc(1.5em + 0.75rem + 1px);
  border-radius: 0;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.buttonload {
  background-color: #4caf50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 16px; /* Set a font-size */
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  width: 40px;
  height: 40px;
}
.carouselTitle {
  font-weight: bold !important;
  word-spacing: normal;
  letter-spacing: normal;
  line-height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  align-items: center;
}
.accordion-list-style {
  font-weight: 600;
  font-style: normal;
}
.custom-caption-style h3 {
  color: rgb(228, 166, 49);
  font-size: 3rem;
  font-weight: bold;
  width: 78%;
  margin: 0 auto;
}
.bg-dark {
  background-color: #992c37 !important;
}

@media screen and (min-device-width: 768px) and (max-device-width: 992px) {
  .dropdown-menu.show > a {
    color: #eaa12e !important;
    font-weight: 700 !important;
  }
  .fixed-nav {
    margin-top: 0;
  }
}
@media screen and (min-device-width: 992px) and (max-device-width: 1024px) {
  .bg-dark {
    background-color: #992c37 !important;
  }

  .dropdown-menu.show > a {
    color: #eaa12e !important;
    font-weight: 700 !important;
  }
}
.accordion .accordion-item.active .panel {
  max-height: 1500px;
}
